import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class VerifiedUserService {

  constructor(private http: HttpClient) { }

  baseUrl = environment.baseUrl

  public getBecomeSherpaProfileView(token:any) {
    return this.http.get(this.baseUrl+"verifiedUser/v1/getBecomeVerifiedUserDetails?token="+token);
  }

  public getSherpaProfileView(_id:any) {
    return this.http.get(this.baseUrl+"verifiedUser/v1/getDetails/"+_id);
  }

  public getBankList() {
    return this.http.get(this.baseUrl+"bank/v1/getList");
  }
}
