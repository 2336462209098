import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MobileResetPasswordService {

  constructor(private http:HttpClient) { }

  baseUrl = environment.baseUrl
  loginUrl = environment.loginUrl;

  public ResetPassword(id:any, data:any) {
    return this.http.put(`${this.loginUrl}user/v1/resetPassword?token=${id}`, data);
  }

  public CheckResetToken(id:any){
    return this.http.get(this.baseUrl+`common/v1/checkResetPassQueryToken?token=${id}`);
  }
}
