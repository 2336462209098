<div class="flex" *ngIf="getBookingStatus(booking) as status">
  <div *ngIf="status === bookingStatus.REQUESTED" class="bg-purple-50 text-purple-600 text-xs px-2 py-1 rounded-sm">
    {{ status }}
  </div>
  <div *ngIf="status === bookingStatus.REJECTED" class="bg-red-50 text-red-600 text-xs px-2 py-1 rounded-sm">
    {{ status }}
  </div>
  <div *ngIf="status === bookingStatus.ACCEPTED" class="bg-yellow-50 text-yellow-600 text-xs px-2 py-1 rounded-sm">
    {{ status }}
  </div>
  <div *ngIf="status === bookingStatus.CONFIRMED" class="bg-green-50 text-green-600 text-xs px-2 py-1 rounded-sm">
    {{ status }}
  </div>
  <div *ngIf="status === bookingStatus.COMPLETED" class="bg-blue-50 text-blue-600 text-xs px-2 py-1 rounded-sm">
    {{ status }}
  </div>
  <div *ngIf="status === bookingStatus.CANCELED || status === bookingStatus.REFUNDED" class="bg-gray-50 text-gray-600 text-xs px-2 py-1 rounded-sm">
    {{ status }}
  </div>
  <div *ngIf="status === bookingStatus.EXPIRED" class="bg-orange-50 text-orange-600 text-xs px-2 py-1 rounded-sm">
    {{ status }}
  </div>
</div>
