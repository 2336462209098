import { Component, Input, OnInit } from '@angular/core';
import { MapboxService } from 'src/app/core/services/mapbox.service';
import * as _ from 'lodash';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-input-suggestions',
  templateUrl: './input-suggestions.component.html',
  styleUrls: ['./input-suggestions.component.scss'],
})
export class InputSuggestionsComponent implements OnInit {
  @Input() name: any;
  @Input() placeholder: any;
  @Input() error: any = null;
  @Input() handelChange: any = () => {};
  @Input() value: any;
  oldSearchText = '';
  isApiLoading = false;
  listAddress: any[] = [];
  showSuggestions = false;
  inputForm!: UntypedFormGroup;

  constructor(private mapboxService: MapboxService) {}

  ngOnInit() {
    this.value = this.value || '';
  }

  onBlurMethod(event: any) {
    setTimeout(() => {
      this.showSuggestions = false;
    }, 200);
  }

  onFocusMethod(event: any) {
    if (this.value !== '') {
      this.oldSearchText = '';
      this.searcAddress(this.value)
    }
  }

  handleClick = (address: any) => {
    this.value = address.fullAddress;
    this.oldSearchText = address.fullAddress;
    this.showSuggestions = false;
    this.handelChange(address.fullAddress);
  };

  searcAddress = (searchText: any) => {
    this.handelChange(searchText);
    if (searchText.length < 3 || this.isApiLoading || this.oldSearchText === searchText) {
      this.oldSearchText = searchText;
      this.showSuggestions = false;
      return;
    }
    this.isApiLoading = true;
    this.mapboxService.geocoding(searchText).subscribe((response: any) => {
      this.listAddress = response?.features?.map((ft: any) => ({
        firstAddress: _.first(_.split(ft.place_name, ', ')),
        lastAddress: _.join(_.drop(_.split(ft.place_name, ', ')), ', '),
        fullAddress: ft.place_name,
      }));
      this.showSuggestions = true;
      this.isApiLoading = false;
      this.oldSearchText = searchText;
    });
  };
}
