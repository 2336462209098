import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ForgotPasswordService } from './forgot-password.service';
import { NotificationService } from '../core/services/notification.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html'
})
export class ForgotPasswordComponent implements OnInit {

  forgotPasswordForm!: UntypedFormGroup;
  hidePassword = true;
  public submitted:boolean = false;

  constructor(
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private forgotPasswordService : ForgotPasswordService,
              private mainNotify : NotificationService
            ) { }

  ngOnInit() {
    
    this.forgotPasswordForm = new UntypedFormGroup({
      Email: new UntypedFormControl('', [Validators.required, Validators.email, Validators.minLength(5), Validators.maxLength(70), Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    });
  }

  HandleForgotClick() {
    this.submitted = true;
    if (this.forgotPasswordForm.invalid) {
      return;
    }
    let data = {
      Email: this.forgotPasswordForm.value.Email
    }
    
    this.forgotPasswordService.ForgotPassword(data).subscribe((result:any) => {
        
      // We've sent link to your email address.
      if(result.code == 200){
        this.mainNotify.showSuccess(result.message,'');
        this.router.navigate(['/login']);
      }
      // The link in your email was not sent. Please try again.
      else if(result.code == 400){
        this.mainNotify.showError(result.message,'')
      }
      // User is not found.
      else if (result.code == 403) {
        this.mainNotify.showError(result.message,'');
      }
      // Sorry! This email is not registered with us.
      else if (result.code == 406) {
        this.mainNotify.showError(result.message,'');        
      }
      else {
        this.mainNotify.showError('Something Went Wrong...','');
      }
    },(err:any) => {
    })
  }
}
