<div class="flex-1">
  <div class="flex-1 bg-white border border-[#D3D3D3] rounded-lg overflow-hidden">
    <table mat-table [dataSource]="dataSource" matSort aria-describedby="mytable">
      <!-- No Column -->
      <ng-container matColumnDef="Amount">
        <th mat-header-cell *matHeaderCellDef id="srNoHeader">Amount</th>
        <td mat-cell *matCellDef="let element; let i = index">${{ (element.TotalAmount / 100).toFixed(2) }}</td>
      </ng-container>

      <!-- Sherpas Name Column -->
      <ng-container matColumnDef="PaymentMethod">
        <th mat-header-cell *matHeaderCellDef>Payment Method</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex items-center">
            <mat-icon *ngIf="element.brand === 'JCB'" svgIcon="JapanCreditBureau" class="!h-[22px] !w-[22px]"></mat-icon>
            <mat-icon *ngIf="element.brand === 'Visa'" svgIcon="Visa" class="!h-[22px] !w-[22px]"></mat-icon>
            <mat-icon *ngIf="element.brand === 'MasterCard'" svgIcon="MasterCard" class="!h-[22px] !w-[22px]"></mat-icon>
            <mat-icon *ngIf="element.brand === 'American Express'" svgIcon="AmericanExpress" class="!h-[22px] !w-[22px]"></mat-icon>
            <mat-icon *ngIf="element.brand === 'Discover'" svgIcon="Discover" class="!h-[22px] !w-[22px]"></mat-icon>
            <mat-icon *ngIf="element.brand === 'Diners Club'" svgIcon="DinersClub" class="!h-[22px] !w-[22px]"></mat-icon>
            <mat-icon *ngIf="element.brand === 'UnionPay'" svgIcon="ChinaUnionPay" class="!h-[22px] !w-[22px]"></mat-icon>
            <span class="pl-1">...{{ element.last4 }}</span>
          </div>
        </td>
      </ng-container>

      <!-- Service Name Column -->
      <ng-container matColumnDef="Description">
        <th mat-header-cell *matHeaderCellDef class="hidden md:table-cell">Description</th>
        <td mat-cell *matCellDef="let element" class="hidden md:table-cell">{{ element?.UserOrder?.OrderCode }}</td>
      </ng-container>

      <!-- Service Name Column -->
      <ng-container matColumnDef="Service">
        <th mat-header-cell *matHeaderCellDef class="hidden md:table-cell">Service</th>
        <td mat-cell *matCellDef="let element" class="hidden md:table-cell">{{ element?.UserOrder?.Service.Name }}</td>
      </ng-container>

      <!-- Amount Column -->
      <ng-container matColumnDef="Date">
        <th mat-header-cell *matHeaderCellDef class="hidden lg:table-cell">Date</th>
        <td mat-cell *matCellDef="let element" class="hidden lg:table-cell">{{ formatTime(element?.createdAt) }}</td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef>Status</th>
        <td mat-cell *matCellDef="let element">
          <div class="flex">
            <div *ngIf="element.PaymentStatus === PAYMENT_STATUS.SUCCEEDED" class="flex items-center bg-green-100 text-green-200 px-2 py-1 rounded">
              <div class="mr-1 text-green-500">{{ element.PaymentStatus }}</div>
              <mat-icon svgIcon="check" class="!h-[10px] !w-auto"></mat-icon>
            </div>
            <div
              *ngIf="element.PaymentStatus === PAYMENT_STATUS.PARTIAL_REFUNDED"
              class="flex items-center bg-indigo-100 text-indigo-200 px-2 py-1 rounded"
            >
              <div class="mr-1 text-indigo-500">{{ element.PaymentStatus }}</div>
              <mat-icon svgIcon="note" class="!h-[10px] !w-auto"></mat-icon>
            </div>
            <div *ngIf="element.PaymentStatus === PAYMENT_STATUS.REFUNDED" class="flex items-center bg-gray-100 text-gray-200 px-2 py-1 rounded">
              <div class="mr-1 text-gray-500">{{ element.PaymentStatus }}</div>
              <mat-icon svgIcon="back" class="!h-[10px] !w-auto"></mat-icon>
            </div>
          </div>
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="Refunded">
        <th mat-header-cell *matHeaderCellDef>Refunded</th>
        <td mat-cell *matCellDef="let element">
          <span *ngIf="element.PaymentStatus === PAYMENT_STATUS.SUCCEEDED">-</span>
          <span *ngIf="element.PaymentStatus !== PAYMENT_STATUS.SUCCEEDED">{{ formatTime(element?.updatedAt) }}</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="!bg-[#E2E2E2]"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        class="hover:bg-BackgroundHover cursor-pointer"
        (click)="setShowModalDetail(row)"
      ></tr>
    </table>
    <div class="bg-white w-full h-72 flex flex-col items-center justify-center" *ngIf="dataSource.length === 0">
      <mat-icon svgIcon="history" class="!h-[60px] !w-[60px] text-gray-400"></mat-icon>
      <p class="text-gray-500 text-xl font-bold m-0 mt-3">No Transaction History Yet</p>
      <p class="text-gray-500 text-base m-0 mt-1">Currently you don’t have any Transaction History.</p>
    </div>
  </div>

  <div class="ngb-pagination" *ngIf="dataSource.length > 0">
    <ngb-pagination
      #paginator
      id="paginator"
      [collectionSize]="total"
      [pageSize]="limit"
      [maxSize]="2"
      [rotate]="true"
      [(page)]="page"
      [boundaryLinks]="true"
      (pageChange)="handlePageChange($event)"
      class="d-flex justify-content-end pagination-rounded-flat pagination-success custom-pagination ms-4"
    >
      <ng-template ngbPaginationPrevious>
        <span>Previous</span>
      </ng-template>
      <ng-template ngbPaginationNext>
        <span>Next</span>
      </ng-template>
      <ng-template ngbPaginationFirst>
        <span>First</span>
      </ng-template>
      <ng-template ngbPaginationLast>
        <span>Last</span>
      </ng-template>
    </ngb-pagination>
  </div>
</div>
<div class="relative">
  <ng-template #modalDetail let-close="close">
    <div class="flex items-center justify-center relative">
      <div class="absolute flex items-center right-2 top-2">
        <button type="button" class="p-2.5" (click)="close()">
          <mat-icon svgIcon="closeIcon" class="!w-[12px] !h-[12px]"></mat-icon>
        </button>
      </div>
      <div class="flex-1 p-7">
        <div class="font-medium text-gray-500 uppercase">Payment</div>
        <div class="flex items-center justify-between border-b !border-Border py-3">
          <div class="flex items-center">
            <div class="text-3xl font-bold text-TextContainer mr-2">${{ (item.TotalAmount / 100).toFixed(2) }}</div>
            <div *ngIf="item.PaymentStatus === PAYMENT_STATUS.SUCCEEDED" class="flex items-center bg-green-100 text-green-200 px-2 py-1 rounded">
              <div class="mr-1 text-green-500">{{ item.PaymentStatus }}</div>
              <mat-icon svgIcon="check" class="!h-[10px] !w-auto"></mat-icon>
            </div>
            <div
              *ngIf="item.PaymentStatus === PAYMENT_STATUS.PARTIAL_REFUNDED"
              class="flex items-center bg-indigo-100 text-indigo-200 px-2 py-1 rounded"
            >
              <div class="mr-1 text-indigo-500">{{ item.PaymentStatus }}</div>
              <mat-icon svgIcon="note" class="!h-[10px] !w-auto"></mat-icon>
            </div>
            <div *ngIf="item.PaymentStatus === PAYMENT_STATUS.REFUNDED" class="flex items-center bg-gray-100 text-gray-200 px-2 py-1 rounded">
              <div class="mr-1 text-gray-500">{{ item.PaymentStatus }}</div>
              <mat-icon svgIcon="back" class="!h-[10px] !w-auto"></mat-icon>
            </div>
          </div>
          <button
            class="flex items-center justify-between bg-gray-50 border border-gray-200 rounded-[10px] py-1.5 px-2.5"
            (click)="setShowModalRefund()"
            *ngIf="item.PaymentStatus !== PAYMENT_STATUS.REFUNDED"
          >
            <mat-icon svgIcon="back" class="!h-[16px] !w-auto"></mat-icon>
            <div class="ml-1.5 text-base font-semibold text-gray-900">Refund</div>
          </button>
        </div>
        <div class="flex items-center border-b !border-Border py-3">
          <div class="border-r !border-Border px-4">
            <div class="text-gray-500 mb-1">Last update</div>
            <div class="font-medium text-TextContainer">{{ formatTime(item?.updatedAt, 'DD MMM, hh:mm') }}</div>
          </div>
          <div class="border-r !border-Border px-4">
            <div class="text-gray-500 mb-1">Customer</div>
            <div class="font-medium text-Primary">{{ getCustomer() }}</div>
          </div>
          <div class="px-4">
            <div class="text-gray-500 mb-1">Payment method</div>
            <div class="font-medium text-TextContainer">
              <div class="flex items-center">
                <mat-icon *ngIf="item.brand === 'JCB'" svgIcon="JapanCreditBureau" class="!h-[22px] !w-[22px]"></mat-icon>
                <mat-icon *ngIf="item.brand === 'Visa'" svgIcon="Visa" class="!h-[22px] !w-[22px]"></mat-icon>
                <mat-icon *ngIf="item.brand === 'MasterCard'" svgIcon="MasterCard" class="!h-[22px] !w-[22px]"></mat-icon>
                <mat-icon *ngIf="item.brand === 'American Express'" svgIcon="AmericanExpress" class="!h-[22px] !w-[22px]"></mat-icon>
                <mat-icon *ngIf="item.brand === 'Discover'" svgIcon="Discover" class="!h-[22px] !w-[22px]"></mat-icon>
                <mat-icon *ngIf="item.brand === 'Diners Club'" svgIcon="DinersClub" class="!h-[22px] !w-[22px]"></mat-icon>
                <mat-icon *ngIf="item.brand === 'UnionPay'" svgIcon="ChinaUnionPay" class="!h-[22px] !w-[22px]"></mat-icon>
                <span class="pl-1">...{{ item.last4 }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="border-b !border-Border py-3">
          <div class="text-base font-medium text-TextContainer">Timeline</div>
          <div class="pt-2" *ngFor="let trans of bookingTransactions(item.Transactions)">
            <div class="text-gray-500">{{ getTransactionReason(trans) }}</div>
            <div class="font-medium text-TextContainer">{{ formatTime(trans?.createdAt, 'DD MMM YYYY, hh:mm') }}</div>
          </div>
        </div>
        <div class="py-3">
          <div class="text-base font-medium text-TextContainer">Payment details</div>
          <div class="pt-1">
            <div class="flex items-center py-1">
              <div class="w-52 text-TextContainer">Statement descriptor</div>
              <div class="text-TextContainer">Stripe</div>
            </div>
            <div class="flex items-center py-1">
              <div class="w-52 text-TextContainer">Amount</div>
              <div class="text-TextContainer">${{ (item.TotalAmount / 100).toFixed(2) }}</div>
            </div>
            <div class="flex items-center py-1" *ngIf="!!item.RefundedAmount">
              <div class="w-52 text-TextContainer">Refunded</div>
              <div class="text-TextContainer">${{ (item.RefundedAmount / 100).toFixed(2) }}</div>
            </div>
            <div class="flex items-center py-1">
              <div class="w-52 text-TextContainer">Fee</div>
              <div class="text-TextContainer">${{ (item.StripeAmount / 100).toFixed(2) }}</div>
            </div>
            <div class="flex items-center py-1">
              <div class="w-52 text-TextContainer">Net</div>
              <div class="text-TextContainer">${{ (item.SherpaAmount / 100).toFixed(2) }}</div>
            </div>
            <div class="flex items-center py-1">
              <div class="w-52 text-TextContainer">Status</div>
              <div class="text-TextContainer">{{ item.PaymentStatus }}</div>
            </div>
            <div class="flex items-center py-1">
              <div class="w-52 text-TextContainer">Description</div>
              <div class="text-TextContainer">{{ item?.UserOrder?.OrderCode }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="relative">
    <ng-template #modalRefund let-close="close">
      <div class="flex items-center justify-center relative">
        <div class="absolute flex items-center right-2 top-2">
          <button type="button" class="p-2.5" (click)="close()">
            <mat-icon svgIcon="closeIcon" class="!w-[12px] !h-[12px]"></mat-icon>
          </button>
        </div>
        <form [formGroup]="refundForm" (ngSubmit)="submitRefund()">
          <div class="flex-1 p-7">
            <div class="font-semibold text-gray-900 text-xl text-center">Refund payment</div>
            <div class="text-xs text-center mt-3">
              Refunds take 5-10 days to appear on a customer’s statement. Stripe’s fees for the original payment won’t be returned, but there are no
              additional fees for the refund. Lear more.
            </div>
            <div class="mt-2">
              <div class="font-medium">Refund</div>
              <mat-form-field appearance="outline" class="service-field w-full">
                <input formControlName="Amount" matInput type="number" (keyup)="validateDecimal($event)" (blur)="onBlur($event)" />
                <span class="text-gray-500">USD</span>
                <mat-error class="reduce-bottom-space" *ngIf="refundForm.controls['Amount'].invalid">
                  <mat-error class="reduce-bottom-space" *ngIf="refundForm.controls['Amount'].errors?.['required']"> Amount is required! </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div>
              <div class="font-medium">Reason</div>
              <mat-form-field appearance="outline" class="sherpa-form-field w-full">
                <mat-select formControlName="reason">
                  <mat-option *ngFor="let rs of reasonSelected" [value]="rs.value">
                    {{ rs.label }}
                  </mat-option>
                </mat-select>
                <mat-icon matSuffix>keyboard_arrow_down</mat-icon>
              </mat-form-field>
            </div>
            <div class="pt-2 flex items-center justify-center">
              <button type="button" (click)="close()" class="px-3 py-2 bg-BackgroundHover rounded mr-4" (click)="close()">CANCEL</button>
              <button type="submit" class="px-4 py-2 bg-Secondary rounded text-white">REFUND ${{ amountRefund }}</button>
            </div>
          </div>
        </form>
      </div>
    </ng-template>
  </div>
</div>
