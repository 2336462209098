<div class="sidenav-menu mt-8">
  <a mat-list-item routerLink="/admin/user-management" routerLinkActive="active-link" class="menu-list-item">
    <mat-icon class="routeIcon material-symbols-rounded" routerLinkActive="active-link">manage_accounts</mat-icon>
    {{ 'USER_MANAGEMENT' | translate }}
  </a>
  <a mat-list-item routerLink="/admin/user-booking-history" routerLinkActive="active-link" class="menu-list-item">
    <mat-icon class="routeIcon material-symbols-rounded" routerLinkActive="active-link">add_shopping_cart</mat-icon>
    {{ 'BOOKING_HISTORY' | translate }}
  </a>
</div>
