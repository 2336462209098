import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { NotificationService } from '../services/notification.service';

@Injectable()
export class InternetInterceptor implements HttpInterceptor {
  constructor(private readonly mainNofify: NotificationService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    // check to see if there's internet
    if (!window.navigator.onLine) {
      this.mainNofify.showError('No Internet Connection!', '');
      return throwError(new Error('Internet is required.'));
    } else {
      // else return the normal request
      return next.handle(request);
    }
  }
}
