import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-open-app',
  templateUrl: './open-app.component.html',
})
export class OpenAppComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
    location.assign('sherpaprovider://bank'); 
  }
}
