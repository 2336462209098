import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { UserService } from 'src/app/core/services/user.services';
import { NotificationService } from 'src/app/core/services/notification.service';
import { ToughClicks, TCEventTypes, TCDisplayMethod } from '@toughclicks/js-client-sdk';
import { environment } from 'src/environments/environment';
import { CATEGORY, Experience } from 'src/app/utils/constant';
import * as _ from 'lodash';
import { SignupService } from './signup.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  signupForm!: UntypedFormGroup;
  public submitted = false;
  decodeToken: any;
  avatarBase64: any;
  avatarFile: any;
  signupSuccess = false;
  hidePassword = true;
  hideConfirmPassword = true;
  isLoading = false;
  tc: any = null;
  allChecked: any = false;
  isBecomeToSherpa: any = false;
  Experience: any[] = Experience;
  CATEGORY: any[] = CATEGORY;
  errorAddress = '';
  userCreate: any = {};

  constructor(private mainNotify: NotificationService, private readonly userService: UserService, private readonly signupService: SignupService) {}

  ngOnInit() {
    this.signupForm = new UntypedFormGroup({
      FirstName: new UntypedFormControl(''),
      DateOfBirth: new UntypedFormControl(''),
      Password: new UntypedFormControl(''),
      ConfirmPassword: new UntypedFormControl(''),
      PhoneNumber: new UntypedFormControl(''),
      Address: new UntypedFormControl(''),
      Unit: new UntypedFormControl(''),
      Email: new UntypedFormControl(''),
      LastName: new UntypedFormControl(''),
    });

    this.tc = new ToughClicks(environment.client_id, environment.packet_id);

    /******** Configurations ********/
    setTimeout(() => {
      const displayOptions = {
        containerId: 'tc-agreements', //required. Div we should inject UI into.
        signerIdSelector: 'userEmailInput', //optional. Input field we should monitor for signer identifier such as an email input in a sign up form.
        signerId: null, //optional. If you have the signer identifier, you can pass it here.
        displayMethod: TCDisplayMethod.group, //optional. Leave blank to control on Webapp
        shouldOpenModal: false, //optional. Leave blank to control on Webapp
        manualAcceptance: true, //optional. If true, you must call tc.accept() manually.
      };
      const validOptions = this.tc.setDisplayOptions(displayOptions);

      if (validOptions) {
        this.tc.render();
        this.tc.on(TCEventTypes.tcValid, (event: any) => {
          this.allChecked = event.detail.allChecked;
        });
        this.tc.on(TCEventTypes.tcAccepted, () => {});
      }
    }, 1000);
  }

  handleSignUp() {
    if (this.isLoading || !this.allChecked) return;
    const password = this.signupForm.controls['Password'];
    const confirmPassword = this.signupForm.controls['ConfirmPassword'];

    if (password.value !== confirmPassword.value) {
      password.setErrors({ incorrect: true });
      confirmPassword.setErrors({ incorrect: true });
      return;
    }
    if (this.signupForm.invalid) {
      return;
    }
    const formData = new FormData();
    Object.keys(this.signupForm.value).forEach((key: string) => {
      formData.append(key, this.signupForm.value[key]);
    });
    if (this.avatarFile) {
      formData.append('FileImage', this.avatarFile);
      formData.append('Image', 'image');
    }
    formData.append('IsIronCladContratAgreed', 'agreed');
    formData.append('IsSherpaLinkOpen', this.isBecomeToSherpa);

    this.isLoading = true;
    this.userService.SignUp(formData).subscribe((response: any) => {
      this.isLoading = false;
      if (response.code == 201) {
        this.userCreate = response.data;
        this.signupSuccess = true;
        this.tc.accept();
        this.mainNotify.showSuccess(response.message, '');
      } else if (response.code == 204) {
        this.mainNotify.showError(response.message, '');
      } else if (response.code == 404) {
        this.mainNotify.showError(response.message, '');
      } else {
        this.mainNotify.showError('Something Went Wrong...', '');
      }
    });
  }

  handleSelectImage(event: any) {
    this.avatarFile = event['target']['files'][0];
    const reader = new FileReader();
    reader.readAsDataURL(this.avatarFile);
    reader.onload = _event => {
      this.avatarBase64 = reader.result;
    };
  }

  handelChangeAddress = (address: any) => {
    this.errorAddress = address.length === 0 ? 'Address is required!' : '';
    this.signupForm.controls['Address'].setValue(address);
  };

  resendVerifyAccountLink = () => {
    this.signupService.resendVerifyAccountLink(this.userCreate.Email).subscribe((res: any) => {
      if (res.code === 200) {
        this.mainNotify.showSuccess(res.message, '');
      } else {
        this.mainNotify.showError(res.message, '');
      }
    });
  };

  checkBecomeToSherpa(event: any) {
    this.isBecomeToSherpa = event.target.checked;
    const value = this.signupForm.value;
    if (event.target.checked) {
      this.signupForm = new UntypedFormGroup({
        FirstName: new UntypedFormControl(''),
        DateOfBirth: new UntypedFormControl(''),
        Password: new UntypedFormControl(''),
        ConfirmPassword: new UntypedFormControl(''),
        PhoneNumber: new UntypedFormControl(''),
        Address: new UntypedFormControl(''),
        Unit: new UntypedFormControl(''),
        Email: new UntypedFormControl(''),
        LastName: new UntypedFormControl(''),
        ActivityPreference: new UntypedFormControl('', [Validators.required]),
        ActivityExpertise: new UntypedFormControl('', [Validators.required]),
        AboutMe: new UntypedFormControl('', [Validators.required, Validators.minLength(50), Validators.maxLength(3000)]),
        TrainingGoals: new UntypedFormControl('', [Validators.required, Validators.minLength(50), Validators.maxLength(3000)]),
        FitnessAchievements: new UntypedFormControl('', [Validators.required, Validators.minLength(50), Validators.maxLength(3000)]),
      });
      const newValue = _.assign(value, { ActivityPreference: '', ActivityExpertise: '', AboutMe: '', TrainingGoals: '', FitnessAchievements: '' });
      this.signupForm.setValue(newValue);
    } else {
      this.signupForm = new UntypedFormGroup({
        FirstName: new UntypedFormControl(''),
        DateOfBirth: new UntypedFormControl(''),
        Password: new UntypedFormControl(''),
        ConfirmPassword: new UntypedFormControl(''),
        PhoneNumber: new UntypedFormControl(''),
        Address: new UntypedFormControl(''),
        Unit: new UntypedFormControl(''),
        Email: new UntypedFormControl(''),
        LastName: new UntypedFormControl(''),
      });
      this.signupForm.setValue(_.omit(value, ['ActivityPreference', 'ActivityExpertise', 'AboutMe', 'TrainingGoals', 'FitnessAchievements']));
    }
  }
}
