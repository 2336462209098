<div class="signup container-fluid d-flex flex-column align-items-sm-center h-100 p-0 bg-white text-base pt-12">
  <div class="w-100 h-100 flex">
    <div name="left-side" id="left-side" class="w-full md:w-[500px] xl:w-[700px] p-4 overflow-auto">
      <div *ngIf="!signupSuccess" class="md:w-[440px] xl:w-[500px] m-auto">
        <div class="mb-2">
          <img class="w-16" src="./assets/image/Temp.png" alt="Go-Sherpa-icon" />
        </div>
        <div class="flex items-center justify-between mb-4">
          <p class="text-2xl md:text-3xl">{{ 'CREATE_ACCOUNT' | translate }}</p>
          <div class="w-14 h-14 md:w-20 md:h-20 rounded-full relative overflow-hidden border border-gray-600">
            <img *ngIf="avatarBase64" src="{{ avatarBase64 }}" alt="" />
            <img *ngIf="!avatarBase64" src="./assets/image/icons/avatar-default.svg" alt="" />
            <div
              class="flex items-center justify-center bg-gray-900 hover:opacity-100 opacity-0 bg-opacity-80 absolute w-full h-full z-10 top-0 left-0"
              (click)="fileImage.click()"
            >
              <img src="./assets/image/icons/pencil30.svg" alt="" />
              <input type="file" accept="image/*" class="hidden" #fileImage (change)="handleSelectImage($event)" />
            </div>
          </div>
        </div>
        <form [formGroup]="signupForm" (ngSubmit)="handleSignUp()" class="form">
          <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-x-4">
            <div>
              <mat-form-field hideRequiredMarker appearance="outline" class="w-full">
                <mat-label>{{ 'FIRST_NAME' | translate }}*</mat-label>
                <input matInput required type="text" formControlName="FirstName" />
              </mat-form-field>
            </div>
            <div>
              <mat-form-field hideRequiredMarker appearance="outline" class="w-full">
                <mat-label>{{ 'LAST_NAME' | translate }}*</mat-label>
                <input matInput required type="text" formControlName="LastName" />
              </mat-form-field>
            </div>
            <div class="md:col-span-2">
              <mat-form-field hideRequiredMarker appearance="outline" class="w-full">
                <mat-label>{{ 'EMAIL_ADDRESS' | translate }}*</mat-label>
                <input matInput required type="text" formControlName="Email" id="userEmailInput" />
              </mat-form-field>
            </div>
            <div>
              <mat-form-field hideRequiredMarker appearance="outline" class="w-full">
                <mat-label>{{ 'PASSWORD' | translate }}*</mat-label>
                <input matInput required [type]="hidePassword ? 'password' : 'text'" formControlName="Password" />
                <mat-icon matSuffix (click)="hidePassword = !hidePassword" class="!text-gray-500">
                  {{ hidePassword ? 'visibility_off' : 'visibility' }}
                </mat-icon>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field hideRequiredMarker appearance="outline" class="w-full">
                <mat-label>{{ 'CONFIRM_PASSWORD' | translate }}*</mat-label>
                <input matInput required [type]="hideConfirmPassword ? 'password' : 'text'" formControlName="ConfirmPassword" />
                <mat-icon matSuffix (click)="hideConfirmPassword = !hideConfirmPassword" class="!text-gray-500">
                  {{ hideConfirmPassword ? 'visibility_off' : 'visibility' }}
                </mat-icon>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field hideRequiredMarker appearance="outline" class="w-full">
                <mat-label>{{ 'MOBILE_NUMBER' | translate }}</mat-label>
                <input
                  matInput
                  mobMask
                  type="text"
                  inputmode="numeric"
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  onselectstart="return false"
                  onpaste="return false"
                  onCopy="return false"
                  onCut="return false"
                  onDrag="return false"
                  onDrop="return false"
                  id="phone"
                  formControlName="PhoneNumber"
                  minlength="12"
                  maxlength="12"
                />
              </mat-form-field>
            </div>
            <div>
              <mat-form-field hideRequiredMarker appearance="outline" class="w-full">
                <mat-label>{{ 'DATE_OF_BIRTH' | translate }}</mat-label>
                <input readonly matInput [matDatepicker]="picker" formControlName="DateOfBirth">
                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="md:col-span-2">
              <app-input-suggestions
                name="Address"
                [handelChange]="handelChangeAddress"
                placeholder="Address"
                [error]="errorAddress"
                [value]="signupForm.value.Address"
              ></app-input-suggestions>
            </div>
            <div class="md:col-span-2 pb-2">
              <mat-form-field hideRequiredMarker appearance="outline" class="w-full">
                <mat-label>{{ 'UNIT' | translate }}</mat-label>
                <input matInput type="text" formControlName="Unit" />
              </mat-form-field>
            </div>
          </div>
          <div class="w-full grid grid-cols-1 md:grid-cols-2 gap-x-4">
            <div class="md:col-span-2">
              <mat-form-field appearance="outline" class="service-field w-full">
                <mat-label>{{ 'ABOUT_ME' | translate }}</mat-label>
                <textarea matInput formControlName="AboutMe" rows="4"></textarea>
                <mat-error class="reduce-bottom-space" *ngIf="signupForm.controls['AboutMe'].invalid">
                  <mat-error class="reduce-bottom-space" *ngIf="signupForm.controls['AboutMe'].errors?.['minlength']">
                    {{ 'MIN_50_REQUIED' | translate }}
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="outline" class="service-field w-full">
                <mat-label>{{ 'TRAINING_GOALS' | translate }}</mat-label>
                <textarea matInput formControlName="TrainingGoals" rows="4"></textarea>
                <mat-error class="reduce-bottom-space" *ngIf="signupForm.controls['TrainingGoals'].invalid">
                  <mat-error class="reduce-bottom-space" *ngIf="signupForm.controls['TrainingGoals'].errors?.['minlength']">
                    {{ 'MIN_50_REQUIED' | translate }}
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="outline" class="service-field w-full">
                <mat-label>{{ 'FITNESS' | translate }}</mat-label>
                <textarea matInput formControlName="FitnessAchievements" rows="4"></textarea>
                <mat-error class="reduce-bottom-space" *ngIf="signupForm.controls['FitnessAchievements'].invalid">
                  <mat-error class="reduce-bottom-space" *ngIf="signupForm.controls['FitnessAchievements'].errors?.['minlength']">
                    {{ 'MIN_50_REQUIED' | translate }}
                  </mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="outline" class="service-field w-full">
                <mat-select formControlName="ActivityPreference" placeholder="{{ 'ACTIVITY_PREFERENCE' | translate }}">
                  <mat-option *ngFor="let preference of CATEGORY" [value]="preference">
                    {{ preference }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field appearance="outline" class="service-field w-full">
                <mat-select formControlName="ActivityExpertise" placeholder="{{ 'ACTIVITY_EXPERIENCE' | translate }}">
                  <mat-option *ngFor="let exp of Experience" [value]="exp">
                    {{ exp }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="flex items-center">
            <div id="tc-agreements" className="tc-agreements"></div>
          </div>
          <button *ngIf="!isLoading && allChecked" type="submit" class="bg-Primary rounded p-2 mt-2 !w-full">{{ 'SIGN_UP' | translate }}</button>
          <button *ngIf="isLoading || !allChecked" type="submit" class="bg-Primary rounded p-2 mt-2 !w-full" disabled>
            {{ 'SIGN_UP' | translate }}
          </button>
          <p class="text-center text-[#5B5B5B] !mt-2">
            {{ 'ALREADY_ACCOUNT' | translate }} <a class="font-semibold" routerLink="/">{{ 'LOGIN' | translate }}</a>
          </p>
        </form>
      </div>
      <div *ngIf="signupSuccess" class="w-[500px] flex flex-col items-center m-auto">
        <img class="mb-6" src="./assets/image/icons/email100.svg" alt="Go-Sherpa-icon" />
        <p class="text-3xl">{{ 'EMAIL_VERIFY' | translate }}</p>
        <p class="mt-2">
          {{ 'EMAIL_SENT_TO' | translate }} <a class="font-semibold">{{ signupForm.value.Email }}</a>
        </p>
        <p class="">{{ 'EMAIL_SENT_NOTE' | translate }}</p>
        <p class="mt-2">
          {{ 'EMAIL_SENDT_TIP' | translate }} <b>{{ 'SPAM' | translate }}</b
          >.
        </p>
        <button (click)="resendVerifyAccountLink()" class="w-auto uppercase bg-Primary text-base font-bold py-3 px-8 rounded my-3">
          {{ 'RESEND_EMAIL' | translate }}
        </button>
        <p class="text-center text-[#5B5B5B] !mt-2">
          {{ 'ALREADY_ACCOUNT' | translate }} <a class="font-semibold" routerLink="/">{{ 'LOGIN' | translate }}</a>
        </p>
      </div>
    </div>
    <div name="right-side text-sm" id="right-side" class="flex-1 p-0">
      <div name="section-side-solid-box" id="section-side-solid-box" class="section-side-login-box h-100"></div>
    </div>
  </div>
</div>
