import { Component, Input } from '@angular/core';
import { bookingStatus } from 'src/app/utils/constant';
import * as _ from 'lodash';
import { getBookingStatus } from 'src/app/utils/helper';

@Component({
  selector: 'app-label-status',
  templateUrl: './label-status.component.html',
})
export class LabelStatusComponent {
  @Input() booking: any;
  bookingStatus = bookingStatus;
  getBookingStatus = getBookingStatus;
  constructor() {}
}
