import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "src/environments/environment";
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BankDetailsService {

  constructor(private http: HttpClient) { }

  public bankCount = new Subject<number>();

  // Create an observable to watch the subject and send out a stream of updates (You will subscribe to this to get the update stream)
  public bankCount$ = this.bankCount.asObservable(); //Has a $

  // ------ How to update the subject ---------
  // Create a method that allows you to update the subject being watched by observable
  public updateBankCount(newBankCount: number) {
    this.bankCount.next(newBankCount);
  }

  baseUrl = environment.baseUrl;

  public addNewBank(data:any) {
    let newData = data;
    if(data?.SetDefault=="true"){
    newData.SetDefault = true
    }else{
    newData.SetDefault = false
    }
    return this.http.post(this.baseUrl+"bank/v1/create",newData);
  }

  public createNewBank() {
    let data;
    return this.http.post(this.baseUrl+"bank/v1/createBankStripe",data);
  }

  public getBankList() {
    return this.http.get(this.baseUrl+"bank/v1/getList");
  }

  public deleteBank(data: any) {
    return this.http.delete(this.baseUrl+"bank/v1/delete/" + data , data );
  }

  public setAsDefaultBank(data: any) {
    return this.http.put(this.baseUrl+"bank/v1/setAsDefault/" + data , data );
  }

  public sherpaOnboarding() {
    let data;
    return this.http.post(this.baseUrl+"bank/v1/SherpaOnboard",data);
  }
}
