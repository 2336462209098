import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { BreakpointObserver } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CommanService } from 'src/app/comman.service';
import { AdminService } from './admin.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/core/services/notification.service';
import Intercom from '@intercom/messenger-js-sdk';
import { environment } from 'src/environments/environment';

export const Breakpoints = {
  Handset: '(max-width: 991px)',
};

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
})
export class AdminComponent implements OnInit {
  title!: string;
  userName = '';
  profileDetail: any[] = [];
  profileImage: any;
  profileFirstName: any;
  profileLastName: any;

  @ViewChild('drawer') drawer: any;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private commanService: CommanService,
    private adminService: AdminService,
    private modalService: NgbModal,
    private mainNotify: NotificationService,
  ) {}

  ngOnInit() {
    this.commanService.title.subscribe(title => {
      this.title = title;
      this.getProfileImage();
    });
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(result => result.matches),
    shareReplay(),
  );
  LogoutBody: any;

  closeSideNav() {
    if (this.drawer._mode == 'over') {
      this.drawer.close();
    }
  }

  viewAdminProfile() {
    this.adminService.getProfileView().subscribe((data: any) => {
      this.profileDetail = data['data'];
      this.router.navigate(['/admin/admin-view-profile']);
    });
  }

  getProfileImage() {
    this.adminService.getProfileView().subscribe((data: any) => {
      this.profileDetail = data['data'];
      this.profileImage = data['data'].Image;
      this.profileFirstName = data['data'].FirstName;
      this.profileLastName = data['data'].LastName;
      Intercom({
        app_id: environment.intercom_app_id,
        user_id: data['data'].UserId,
        name: `${data['data'].FirstName} ${data['data'].LastName}`,
        email: data['data'].Email,
        created_at: data['data'].createdAt,
      });
    });
  }

  role: any;
  changePassword() {
    this.role = localStorage.getItem('role');
    this.router.navigate(['/change-password'], { state: { role: this.role } });
  }

  openVerticallyCentered(delete_form: any) {
    this.modalService.open(delete_form, { centered: true, windowClass: 'delete-form' });
  }

  logout() {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('role');
    this.router.navigate(['login']);
    this.mainNotify.showSuccess('You have successfully logged out.', '');
  }
}
