<div class="sidenav-menu mt-8">
  <a mat-list-item routerLink="/admin/user-management" routerLinkActive="active-link" class="menu-list-item">
    <div class="flex items-center">
      <mat-icon class="material-symbols-outlined text-[#A0A0A0]" routerLinkActive="active-link" style="fill: 1">manage_accounts</mat-icon>
      <span class="text-base ml-2">{{ 'USER_MANAGEMENT' | translate }}</span>
    </div>
  </a>
  <a mat-list-item routerLink="/admin/user-booking-history" routerLinkActive="active-link" class="menu-list-item">
    <div class="flex items-center">
      <mat-icon class="material-symbols-outlined text-[#A0A0A0]" routerLinkActive="active-link" style="fill: 1">add_shopping_cart</mat-icon>
      <span class="text-base ml-2">{{ 'BOOKING_HISTORY' | translate }}</span>
    </div>
  </a>
</div>
