<div class="flex-1 pb-4">
  <div class="!px-4" *ngIf="!isTable">
    <div class="flex-1 !py-3 border-b !border-Border" *ngFor="let element of dataSource">
      <div class="flex items-center justify-between" *ngIf="getUser(element) as user">
        <div class="flex items-center">
          <div *ngIf="!user?.Images?.[0]" class="bg-[#EDEDED] w-9 h-9 rounded-full flex items-center justify-center">
            <mat-icon svgIcon="user" class="!w-6 !h-6"></mat-icon>
          </div>
          <img *ngIf="user?.Images?.[0] as image" src="{{ image }}" class="object-cover rounded-full w-9 h-9" />
          <div class="!pl-2.5">
            <div class="text-base font-medium">{{ user?.FirstName }} {{ user?.LastName }}</div>
            <div class="text-gray-500 text-xs pt-0.5">{{ formatTimeRequest(element?.createdAt) }}</div>
          </div>
        </div>
        <div class="flex items-center bg-LightSecondary rounded px-1.5 py-0.5">
          <mat-icon svgIcon="star" class="!h-[13px] !w-[13px]"></mat-icon>
          <span class="text-ActiveSecondary pl-1">{{ element?.Rating }}.0</span>
        </div>
      </div>
      <div class="text-gray-500 !pt-2">{{ element.Comment }}</div>
    </div>
  </div>
  <div class="flex-1 bg-white border border-[#D3D3D3] rounded-lg overflow-hidden" *ngIf="isTable">
    <table mat-table [dataSource]="dataSource" matSort aria-describedby="mytable">
      <ng-container matColumnDef="_id">
        <th mat-header-cell *matHeaderCellDef>{{ userId ? 'Sherpa' : 'Athlete' }}'s {{ 'REVIEW' | translate }}</th>
        <td mat-cell *matCellDef="let element; let i = index" class="">
          <div class="flex-1 !p-4 border-r !border-Border">
            <div class="flex items-center justify-between" *ngIf="getUser(element) as user">
              <div class="flex items-center">
                <div *ngIf="!user?.Images?.[0]" class="bg-[#EDEDED] w-9 h-9 rounded-full flex items-center justify-center">
                  <mat-icon svgIcon="user" class="!w-6 !h-6"></mat-icon>
                </div>
                <img *ngIf="user?.Images?.[0] as image" src="{{ image }}" class="object-cover rounded-full w-9 h-9" />
                <div class="!pl-2.5">
                  <div class="text-base font-medium">{{ user?.FirstName }} {{ user?.LastName }}</div>
                  <div class="text-gray-500 text-xs pt-0.5">{{ formatTimeRequest(element?.createdAt) }}</div>
                </div>
              </div>
              <div class="flex items-center bg-LightSecondary rounded px-1.5 py-0.5">
                <mat-icon svgIcon="star" class="!h-[13px] !w-[13px]"></mat-icon>
                <span class="text-ActiveSecondary pl-1">{{ element?.Rating }}.0</span>
              </div>
            </div>
            <div class="text-gray-500 !pt-2">{{ element.Comment }}</div>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="w-20"></th>
        <td mat-cell *matCellDef="let element; let i = index" class="!p-4">
          <div class="flex items-center">
            <button
              class="mr-2 h-[30px] w-[30px] flex items-center justify-center {{ element.isHidden ? 'bg-Primary' : 'bg-BackgroundHover' }}"
              (click)="handleChangeHidden(element)"
            >
              <mat-icon svgIcon="checked" class="!h-[8px] !w-auto {{ element.isHidden ? 'text-white' : 'text-gray-400' }}"></mat-icon>
            </button>
            <span>Hidden</span>
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" class="!bg-[#E2E2E2]"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" class="hover:bg-Border cursor-pointer"></tr>
    </table>
    <div class="bg-white w-full h-72 flex flex-col items-center justify-center" *ngIf="dataSource.length === 0">
      <mat-icon svgIcon="star60" class="!h-[60px] !w-[60px]"></mat-icon>
      <p class="text-gray-500 text-xl font-bold m-0 mt-3">{{ 'REVIEW_EMPTY' | translate }}</p>
      <p class="text-gray-500 text-base m-0 mt-1">{{ 'REVIEW_EMPTY_NOTE' | translate }}</p>
    </div>
  </div>

  <div class="ngb-pagination" *ngIf="dataSource.length > 0">
    <ngb-pagination
      #paginator
      id="paginator"
      [collectionSize]="total"
      [pageSize]="limit"
      [maxSize]="2"
      [rotate]="true"
      [(page)]="page"
      [boundaryLinks]="true"
      (pageChange)="handlePageChange($event)"
      class="d-flex justify-content-end pagination-rounded-flat pagination-success custom-pagination ms-4"
    >
      <ng-template ngbPaginationPrevious>
        <span>{{ 'PREVIOUS' | translate }}</span>
      </ng-template>
      <ng-template ngbPaginationNext>
        <span>{{ 'NEXT' | translate }}</span>
      </ng-template>
      <ng-template ngbPaginationFirst>
        <span>{{ 'FIRST' | translate }}</span>
      </ng-template>
      <ng-template ngbPaginationLast>
        <span>{{ 'LAST' | translate }}</span>
      </ng-template>
    </ngb-pagination>
  </div>
</div>
