import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  token:any ;
  decode:any;
  id:any ='';
  
  constructor(private readonly http: HttpClient) {
    this.token = localStorage.getItem('auth_token');
    if (this.token && this.token != null) {
      this.decode = jwt_decode(this.token);
      this.id = this.decode._id;
      this.chageVerifiedSherpaId(this.id);
    }
  }
  private readonly VerifiedSherpaId = new BehaviorSubject<any>(this.id);
  currentVerifiedSherpaId = this.VerifiedSherpaId.asObservable();
  chageVerifiedSherpaId(data:any ){
    this.VerifiedSherpaId.next(data)
  }
  loginUrl = environment.loginUrl

  public login(loginData: any) {
    return this.http.post(this.loginUrl+"common/v1/login", loginData);
  }
}
