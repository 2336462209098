import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpResponse, HttpRequest, HttpHandler } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NotificationService } from '../services/notification.service';

@Injectable()
export class MyInterceptor implements HttpInterceptor {
  constructor(private readonly router: Router, private readonly ngxLoader: NgxUiLoaderService, private readonly mainNotify: NotificationService) {}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('auth_token');

    if (token) {
      const authReq = httpRequest.clone({
        headers: httpRequest.headers.set('authorization', token).set('Access-Control-Allow-Origin', '*'),
      });
      httpRequest = authReq;
      if (!httpRequest.headers.get('notloading')) this.ngxLoader.start();
    }

    return next.handle(httpRequest).pipe(
      tap(
        event => this.handleResponse(httpRequest, event),
        error => this.handleError(httpRequest, error),
      ),
    );
  }

  handleResponse(httpRequest: HttpRequest<any>, event: any) {
    if (event?.type !== 0) {
      this.ngxLoader.stop();
    }
    if (event instanceof HttpResponse) {
      // for dynamic URL to fatch data
      //      ' Response Status ', event.status,
      //      ' With body ', event.body);
    }

    if (event?.body?.code === 401) {
      localStorage.clear();
      sessionStorage.clear();
      setTimeout(() => {
        this.router.navigate(['/']);
      }, 1000);
    }
  }

  handleError(httpRequest: HttpRequest<any>, error: any) {
    this.ngxLoader.stop();

    if (error?.status === 401 || error?.status === 304) {
      localStorage.clear();
      sessionStorage.clear();
      setTimeout(() => {
        this.router.navigate(['/']);
      }, 1000);
    }
  }
}
