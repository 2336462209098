import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import Validation from 'src/app/utils/validation';
import { ChangePasswordService } from './change-password.service';
import { NotificationService } from 'src/app/core/services/notification.service';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html'
})
export class ChangePasswordComponent implements OnInit {

  changePasswordForm!: UntypedFormGroup;
  hidePassword = true;
  hideNewPassword = true;
  hideOldPassword = true;
  public submitted = false;
  userRole = window.history.state;
  decodeToken: any;

  constructor(private readonly router: Router,
              private readonly activatedRoute: ActivatedRoute,
              private readonly changePassword : ChangePasswordService,
              private readonly mainNotify : NotificationService,
              private readonly jwtHelperService: JwtHelperService
            ) { }

  ngOnInit(): void {
    this.changePasswordForm = new UntypedFormGroup({
      OldPassword: new UntypedFormControl('', [
        Validators.required, 
        Validators.minLength(8),
        Validators.pattern('^(?! ).*[^ ]$')
      ]),
      NewPassword: new UntypedFormControl('', [
        Validators.required, 
        Validators.minLength(8), 
        Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
      ]),
      ConfirmPassword: new UntypedFormControl('', [
        Validators.required, 
        Validators.minLength(8), 
        Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')
      ])
    },
    {
      validators: [Validation.match('NewPassword', 'ConfirmPassword')]
    });
  }

  HandleChangePasswordSubmit() {
    this.submitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }
    if(this.changePasswordForm.value.OldPassword === this.changePasswordForm.value.NewPassword){
        this.mainNotify.showError('The new password you entered is the same as your old password. Enter a different password.','');
      return;
    }
    const token = (localStorage.getItem("auth_token") || '{}')
    this.decodeToken = this.jwtHelperService.decodeToken(token);
    this.changePassword.ChangePassword(this.changePasswordForm.value).subscribe((result:any) => {

      // You've successfully changed your password.
      if (result.code === 200) {
        this.mainNotify.showSuccess(result.message,'');
        if (this.decodeToken['AccountType'] === 1) {
          this.router.navigate(['/athlete-page']);
        } else if (this.decodeToken['AccountType'] === 4 || this.decodeToken['AccountType'] === 5) {
          this.router.navigate(['/admin/user-management']);
        }
        else if (this.decodeToken['AccountType'] === 2) {
          this.router.navigate(['/verified-user/availability']);
        }
      } 

      // The new password you entered is the same as your old password. Enter a different password.
      else if (result.code === 409) {
        this.mainNotify.showError(result.message,'');
      }
      // Sorry! Your old password does not matched.
      else if (result.code === 404) {
        this.mainNotify.showError(result.message,'');
      }
      // Admin is not found.
      else if (result.code === 204) {
        this.mainNotify.showError(result.message,'');
      }
      else {
        this.mainNotify.showError('Something Went Wrong...','');
      }
    });
  }

}
