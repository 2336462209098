<div appearance="outline" class="input-suggestions relative w-full">
  <input
    [class]="error ? 'input-error' : ''"
    [placeholder]="placeholder"
    (keyup)="searcAddress(value)"
    [name]="name"
    [(ngModel)]="value"
    autocomplete="off"
    (blur)="onBlurMethod($event)"
    (focus)="onFocusMethod($event)"
  />
  <div class="suggestions-wrapper absolute left-0 top-[50px] z-10 bg-white shadow-lg shadow-inner w-full" *ngIf="showSuggestions">
    <ul class="">
      <li class="px-3 py-2 hover:bg-gray-100 cursor-pointer" *ngFor="let address of listAddress" (click)="handleClick(address)">
        <div class="font-semibold pb-1 text-gray-900">{{ address?.firstAddress }}</div>
        <div class="text-gray-500">{{ address?.lastAddress }}</div>
      </li>
    </ul>
  </div>
  <div class="error leading-4">
    <span *ngIf="!!error" class="text-[10px]"> {{ error }} </span>
  </div>
</div>
