import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
})
export class SignupComponent implements OnInit {
  signupLink = environment.signup_link;
  constructor() {
    window.location.href = this.signupLink;
  }

  ngOnInit() {}
}
