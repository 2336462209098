import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobile-provider',
  templateUrl: './mobile-provider.component.html',
})
export class MobileProviderComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
    location.assign('sherpaprovider://bank'); 
  }
}
