import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MaterialModuleModule } from 'src/app/material-module/material-module/material-module.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { ConversationsComponent } from './conversations/conversations.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { MessagesComponent } from './messages/messages.component';
import { LabelStatusComponent } from './label-status/label-status.component';
import { ServicesComponent } from './services/services.component';
import { ProfileComponent } from './profile/profile.component';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { TranslateModule } from '@ngx-translate/core';
import { InputSuggestionsComponent } from './input-suggestions/input-suggestions.component';
import { PhoneDirective } from './phoneNumber';
import { TextLazyComponent } from './text-lazy/text-lazy.component';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
    ConversationsComponent,
    ReviewsComponent,
    MessagesComponent,
    LabelStatusComponent,
    ServicesComponent,
    ProfileComponent,
    TransactionHistoryComponent,
    InputSuggestionsComponent,
    PhoneDirective,
    TextLazyComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatTableModule,
    MaterialModuleModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    ClipboardModule,
    TranslateModule,
  ],
  exports: [
    ConversationsComponent,
    ReviewsComponent,
    MessagesComponent,
    LabelStatusComponent,
    ServicesComponent,
    ProfileComponent,
    TransactionHistoryComponent,
    InputSuggestionsComponent,
    PhoneDirective,
    TextLazyComponent,
  ],
})
export class ComponentModule {}
