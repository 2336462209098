import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {

  constructor(private http:HttpClient) { }

  loginUrl = environment.loginUrl;

  public ForgotPassword(data:any) {
    return this.http.post(this.loginUrl+"common/v1/forgotPassword", data);
  }
}
