import { APP_INITIALIZER, NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminComponent } from './main/admin/admin.component';
import { VerifiedUserComponent } from './main/verified-user/verified-user.component';
import { LoginComponent } from './auth/login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModuleModule } from './material-module/material-module/material-module.module';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { CommanService } from './comman.service';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { MyInterceptor } from './core/interceptor/http.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthenticationService } from './core/services/authentication.service';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderModule, NgxUiLoaderConfig } from 'ngx-ui-loader';
import { InternetInterceptor } from './core/interceptor/internet.interceptor';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';
import { MatSortModule } from '@angular/material/sort';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PhoneDirective } from './phoneNumber';
import { ssnDirective } from './ssnNumber';
import { MobileResetPasswordComponent } from './mobile-reset-password/mobile-reset-password.component';
import * as Sentry from '@sentry/angular';
import { VerifyAccountComponent } from './verify-account/verify-account.component';
import { SignupComponent } from './auth/signup/signup.component';
import { RegistrationComponent } from './auth/registration/registration.component';
import { AthletePageComponent } from './auth/athlete-page/athlete-page.component';
import { ComponentModule } from './component/component.module';
import { RegisterSherpaComponent } from './auth/register-sherpa/register-sherpa.component';
import { MobileProviderComponent } from './auth/mobile-provider/mobile-provider.component';
const decSocketUrl = environment.socketUrl;
const decSocketPath = environment.socketPath;
const config: SocketIoConfig = { url: decSocketUrl, options: { path: decSocketPath } };

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  fgsSize: 50,
  pbColor: '#01C3A7',
  fgsColor: '#01C3A7',
  overlayColor: 'rgba(0, 0, 0, 0.1)',
  hasProgressBar: false,
};

export const jwtOptionsFactory = (dependency: any) => ({
  tokenGetter: () => dependency.getToken(),
  whitelistedDomains: [],
});

export function tokenGetter() {
  return localStorage.getItem('auth_token');
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    VerifiedUserComponent,
    LoginComponent,
    SignupComponent,
    RegistrationComponent,
    AthletePageComponent,
    RegisterSherpaComponent,
    ForgotPasswordComponent,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    ResetPasswordComponent,
    ChangePasswordComponent,
    PhoneDirective,
    ssnDirective,
    MobileResetPasswordComponent,
    VerifyAccountComponent,
    MobileProviderComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    ComponentModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModuleModule,
    MatSortModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    SocketIoModule.forRoot(config),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      newestOnTop: true,
      progressBar: true,
      closeButton: true,
    }),
    JwtModule.forRoot({ config: { tokenGetter: tokenGetter } }),
    TranslateModule.forRoot({ loader: { provide: TranslateLoader, useFactory: createTranslateLoader, deps: [HttpClient] } }),
    NgbModule,
  ],
  providers: [
    CommanService,
    AuthenticationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InternetInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
