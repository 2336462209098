import { Component, OnInit, AfterViewInit } from '@angular/core';
import { VerifyAccountService } from './verify-account.service';
import { NotificationService } from '../core/services/notification.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ACCOUNT_TYPE } from 'src/app/utils/constant';

@Component({
  selector: 'app-verify-account',
  templateUrl: './verify-account.component.html'
})
export class VerifyAccountComponent implements OnInit {

  queryToken!: any;
  responsestatus : any;
  responsestatus1 : any;
  HideLink! : any;
  apiCalled: boolean = false;

  constructor(private verifyAccountService: VerifyAccountService,
              private mainNotify : NotificationService,
              private router: Router,
              private activatedRoute: ActivatedRoute
            ) { 
              this.queryToken = this.activatedRoute.snapshot.queryParams['token'];
              this.VerifyEmail();
            }


  ngOnInit(): void {
    // this.VerifyEmail();
  }

  VerifyEmail(){
    this.verifyAccountService.VerifiyAccount(this.queryToken).subscribe((result:any) => {

      if(result.code == 200){
        this.mainNotify.showSuccess(result.message,'');
        this.responsestatus=true;
        this.responsestatus1=true;
        if (result.data.IsSherpaLinkOpen) {  
          this.router.navigate(['/login']);
        }else {
          this.router.navigate(['/verify-account']);
        }
        
      }

      // For Link Expire
      else if(result.code == 422){        
        this.mainNotify.showError(result.message,'');
        this.responsestatus=false;
        this.responsestatus1=true;
        // this.router.navigate(['/verify-account']);
      }

      // Link Is Not Valid
      else if(result.code == 403){
        this.mainNotify.showError(result.message,'');
        this.responsestatus=false;
        this.responsestatus1=false;
        this.HideLink = result.code;
        this.router.navigate(['/verify-account']);
      }

      // For already verified
      else if(result.code == 406){
        this.mainNotify.showError(result.message,'');
        this.router.navigate(['/login']);
      }

      else if(result.code == 400){
        this.mainNotify.showError(result.message,'');
      }

      else {
        this.mainNotify.showError('Something Went Wrong...','');
      }
    })
  }

  ResendVerifyLink(){
    this.verifyAccountService.ResendLink(this.queryToken).subscribe((result:any) => {

      if(result.code == 200){
        this.mainNotify.showSuccess(result.message,'');
        this.router.navigate(['/login']);
      }

      else if(result.code == 403){        
        this.mainNotify.showError(result.message,'');
        this.router.navigate(['/verify-account']);
      }

      else if(result.code == 400){
        this.mainNotify.showError(result.message,'');
        this.router.navigate(['/login']);
      }

      else {
        this.mainNotify.showError('Something Went Wrong...','');
      }
    })
  }
}
