import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class VerifyAccountService {
  unsubscribe() {
    throw new Error('Method not implemented.');
  }

  constructor(private http:HttpClient) { }

  baseUrl = environment.baseUrl

  public VerifiyAccount(id:any) {
    return this.http.get(this.baseUrl+`common/v1/verifyAccount?token=${id}`);
  }

  public ResendLink(id:any) {
    return this.http.get(this.baseUrl+`common/v1/resendVerifyAccountLink?token=${id}`);
  }
}
