export const environment = {
  production: false,
  dis_alert_timeout: 3000,
  socketUrl: 'https://api.dev.gowithsherpa.com/',
  socketPath: '/gosherpa/socket.io',
  loginUrl: 'https://api.dev.gowithsherpa.com/api/',
  baseUrl: 'https://api.dev.gowithsherpa.com/api/',
  crypto_key: 'asdfghjk2345678xcvbnm0765erty234vbn',
  crypto_iv: 'cvbery987dfrewqre43438798dbfdt43vffxd67',
  mapBoxAccessToken: 'pk.eyJ1IjoiZ293aXRoc2hlcnBhIiwiYSI6ImNsbmx2bzJhcDBjdWYyam55eHh3eWNxem8ifQ.PQLcaIBXiP96lFWvsDhy0w',
  packet_id: 'dc4d296d-130d-4239-920f-dea3ab8e6a14',
  client_id: '0dccf9b4-ca4c-4210-ab1f-1b51a1ac101e',
  intercom_app_id: "l2hpbrqs",
  signup_link: 'https://3m3ss7jsuc1.typeform.com/to/l3Dzh5wp?typeform-source=gowithsherpa.atlassian.net',
  dashboardStripe: 'https://dashboard.stripe.com/connect/accounts/'
};
