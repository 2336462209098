<div class="container-fluid d-flex flex-column align-items-sm-center h-100 p-0">
  <div class="row w-100 h-100 m-auto">
    <div name="left-side" id="left-side" class="col-lg-5 p-0">
      <div name="section-box" id="section-box" class="section-box login-color">
        <section name="section-content" id="section-content" class="section-content">
          <form name="login-form" id="login-form" class="form form-box" [formGroup]="loginForm" (ngSubmit)="HandleLogin()">
            <div class="flex justify-start">
              <img class="logo-sherpa" src="./assets/image/Temp.png" alt="Go-Sherpa-icon" />
            </div>
            <div class="section-content-inner">
              <h1 class="top-head">{{ 'WELCOME' | translate }}</h1>
              <p class="top-text">{{ 'SIGN_IN_DEPSCRIPTION' | translate }}</p>
            </div>
            <div name="form-content" id="form-content" class="form-content">
              <mat-form-field hideRequiredMarker appearance="outline" name="mat-form-field-email" id="mat-form-field-email">
                <input matInput required type="text" name="vEmailId" id="vEmailId" placeholder="Email Address" formControlName="vEmailId" />
                <mat-error
                  class="reduce-bottom-space"
                  *ngIf="loginForm.controls['vEmailId']?.['invalid'] || submitted || loginForm.controls['vEmailId']?.errors"
                >
                  <mat-error class="reduce-bottom-space" *ngIf="loginForm.controls['vEmailId'].errors?.['required']"
                    >{{ 'EMAIL_REQUIRED' | translate }}</mat-error
                  >
                  <mat-error
                    class="-bottom-space"
                    *ngIf="loginForm.controls['vEmailId'].errors?.['email'] || loginForm.controls['vEmailId'].errors?.['pattern']"
                    >{{ 'EMAIL_VALID' | translate }}</mat-error
                  >
                  <mat-error
                    class="reduce-bottom-space"
                    *ngIf="(loginForm.controls['vEmailId'].errors?.['minlength'] || loginForm.controls['vEmailId'].errors?.['maxlength']) && !(loginForm.controls['vEmailId'].errors?.['email'] || loginForm.controls['vEmailId'].errors?.['pattern'])"
                    >{{ 'EMAIL_LENGTH' | translate }}</mat-error
                  >
                </mat-error>
              </mat-form-field>
              <mat-form-field hideRequiredMarker appearance="outline" name="mat-form-field-password" id="mat-form-field-password">
                <input
                  matInput
                  required
                  [type]="hidePassword ? 'password' : 'text'"
                  name="txPassword"
                  id="txPassword"
                  placeholder="Password"
                  formControlName="txPassword"
                />
                <mat-icon
                  matSuffix
                  (click)="hidePassword = !hidePassword"
                  name="password-visibility-eye"
                  id="password-visibility-eye"
                  class="d-flex justify-content-center align-items-center h-100 visibility-eye"
                >
                  {{ hidePassword ? 'visibility_off' : 'visibility' }}
                </mat-icon>
                <mat-error
                  class="reduce-bottom-space"
                  *ngIf="loginForm.controls['txPassword']?.['invalid'] || submitted || loginForm.controls['txPassword']?.errors"
                >
                  <mat-error class="reduce-bottom-space" *ngIf="loginForm.controls['txPassword'].errors?.['required']"
                    >{{ 'PASS_REQUIRED' | translate }}</mat-error
                  >
                  <mat-error class="reduce-bottom-space" *ngIf="loginForm.controls['txPassword'].errors?.['minlength']"
                    >{{ 'PASS_LENGTH' | translate }}
                  </mat-error>
                  <mat-error class="reduce-bottom-space" *ngIf="loginForm.controls['txPassword'].errors?.['pattern']">{{ 'INVALID_PATTERN' | translate }}</mat-error>
                </mat-error>
              </mat-form-field>
            </div>
            <div name="form-footer" id="form-footer" class="form-footer text-end">
              <a name="link" id="link" class="link" routerLink="/forgot-password">{{ 'FORGOT_PASSWORD' | translate }}</a>
              <button type="submit" name="form-button" id="form-button" class="btn">{{ 'LOGIN' | translate }}</button>
            </div>
            <div>
              <p class="text-center text-base text-[#5B5B5B]">
                {{ 'DONT_ACCOUNT' | translate }} <a class="text-[#58c0a8] font-semibold" routerLink="/signup">{{ 'SIGN_UP' | translate }}</a>
              </p>
            </div>
          </form>
        </section>
      </div>
    </div>
    <div name="right-side" id="right-side" class="col-lg-7 d-none d-lg-block p-0">
      <div name="section-side-solid-box" id="section-side-solid-box" class="section-side-login-box h-100"></div>
    </div>
  </div>
</div>
