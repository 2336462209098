import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthGuard } from './core/guards/auth.guard';
import { MobileResetPasswordComponent } from './mobile-reset-password/mobile-reset-password.component';
import { VerifyAccountComponent } from './verify-account/verify-account.component';
import { SignupComponent } from './auth/signup/signup.component';
import { RegistrationComponent } from './auth/registration/registration.component';
import { AthletePageComponent } from './auth/athlete-page/athlete-page.component';
import { RegisterSherpaComponent } from './auth/register-sherpa/register-sherpa.component';
import { MobileProviderComponent } from './auth/mobile-provider/mobile-provider.component';

const routes: Routes = [
  { path: 'signup', component: SignupComponent },
  { path: 'register-sherpa', component: RegisterSherpaComponent },
  { path: 'registration', component: RegistrationComponent },
  { path: 'athlete-page', component: AthletePageComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'change-password', component: ChangePasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'mobile-provider', component: MobileProviderComponent },
  { path: 'reset-password/:id', component: ResetPasswordComponent },
  { path: 'mobile-reset-password', component: MobileResetPasswordComponent },
  { path: 'mobile-reset-password/:id', component: MobileResetPasswordComponent },
  { path: 'verify-account', component: VerifyAccountComponent },
  { path: 'verify-account/:id', component: VerifyAccountComponent },
  {
    path: 'admin',
    loadChildren: () => import('./main/admin/admin.module').then(m => m.AdminModule),
    canActivate: [AuthGuard],
    data: { allowedRoles: [4, 5] },
  },
  {
    path: 'verified-user',
    loadChildren: () => import('./main/verified-user/verified-user.module').then(m => m.VerifiedUserModule),
    canActivate: [AuthGuard],
    data: { allowedRoles: [2] },
  },
  { path: '', component: LoginComponent },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
